import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';

function Login() {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card text-center">
            <div className="card-header">
              Welcome to MyApp
            </div>
            <div className="card-body">
              {!isAuthenticated && (
                <>
                  <h5 className="card-title">Please Log In</h5>
                  <p className="card-text">Access the features of MyApp by logging in.</p>
                  <LoginButton />
                </>
              )}
              {isAuthenticated && (
                <>
                  <h5 className="card-title">Welcome Back!</h5>
                  <p className="card-text">You are logged in to MyApp.</p>
                  <LogoutButton />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
