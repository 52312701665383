import { useState, useEffect } from 'react';
import ChartComponent from './ChartComponent';
import { useAuth0 } from "@auth0/auth0-react";

const domain = window.location.host;

function Home() {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [maxNumber, setMaxNumber] = useState(null);

  useEffect(() => {
    const sendPangRequest = async () => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: `https://${domain}/api/`,
          scope: "read:current_user"
        },
      });

      fetch('/api/pang', {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        }
      })
        .then(response => response.text())
        .then(data => console.log(data))
        .catch(error => console.error('Error panging server:', error));
    };

    fetchMaxNumber()
      .finally(() => {
        sendPingRequest();
        if (isAuthenticated) {
          sendPangRequest();
        }
      });
  }, [getAccessTokenSilently, isAuthenticated]);

  const sendPingRequest = () => {
    fetch('/api/ping')
      .then(response => response.text())
      .then(data => console.log(data))
      .catch(error => console.error('Error pinging server:', error));
  };

  const fetchMaxNumber = () => {
    return fetch('/api/max')
      .then((response) => response.json())
      .then((data) => setMaxNumber(data.maxCount))
      .catch((error) => console.error('Error fetching max number:', error));
  };

  const handleAdd = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: `https://${domain}/api/`,
        scope: "read:current_user"
      },
    });

    fetch('/api/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify({})
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }
        return response.text();
      })
      .then(() => fetchMaxNumber())
      .catch((error) => console.error('Error on add:', error));
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container text-center mt-5">
      <div className="card p-4">
        <div className="card-body">
          <h5 className="card-title">Query Database</h5>
          <p className="card-text">Max number: {maxNumber !== null ? maxNumber : 'Loading...'}</p>
          <button className="btn btn-primary" onClick={handleAdd} disabled={!isAuthenticated}>Add</button>
        </div>
      </div>
      <div className="card p-4 mt-4">
        <div className="card-body">
          <h5 className="card-title">Market Trends</h5>
          <ChartComponent />
        </div>
      </div>
    </div >
  );
}

export default Home;
