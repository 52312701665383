import React, { useState, useEffect, useMemo } from 'react';
import { useTable } from 'react-table';
import { useAuth0 } from "@auth0/auth0-react";

function TransactionsComponent() {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTransactions = async () => {
      if (!isAuthenticated) {
        setLoading(false);
        return;
      }

      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: `https://${window.location.host}/api/`,
            scope: "read:transactions",
          },
        });

        const response = await fetch('/api/transactions', {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }

        const data = await response.json();
        setTransactions(data);
      } catch (error) {
        console.error('Error fetching transactions:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [isAuthenticated, getAccessTokenSilently]);

  const data = useMemo(() => transactions, [transactions]);

  const columns = useMemo(
    () => [
      {
        Header: 'Transaction ID',
        accessor: 'id',
      },
      {
        Header: 'Position ID',
        accessor: 'position_id',
      },
      {
        Header: 'Account ID',
        accessor: 'account_id',
      },
      {
        Header: 'Timestamp',
        accessor: 'time_stamp',
        Cell: ({ value }) => {
          return new Date(value).toLocaleString();
        },
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'From Instrument Name',
        accessor: 'from_instrument_name',
      },
      {
        Header: 'From Amount',
        accessor: 'from_amount',
      },
      {
        Header: 'To Instrument Name',
        accessor: 'to_instrument_name',
      },
      {
        Header: 'To Amount',
        accessor: 'to_amount',
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  if (loading) {
    return <div>Loading transactions...</div>;
  }

  if (error) {
    return <div>Error loading transactions: {error.message}</div>;
  }

  return (
    <div className="card p-4 mt-4">
      <div className="card-body">
        <h5 className="card-title">Transactions</h5>
        <table
          {...getTableProps()}
          className="table table-striped"
        >
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      borderBottom: '2px solid black',
                      background: 'aliceblue',
                      fontWeight: 'bold',
                      padding: '10px',
                    }}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: '10px',
                          border: 'solid 1px gray',
                        }}
                      >
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={columns.length}
                  style={{ textAlign: 'center', padding: '10px' }}
                >
                  No transactions found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TransactionsComponent;
