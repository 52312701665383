import React, { useRef, useEffect } from 'react';
import { createChart } from 'lightweight-charts';

function ChartComponent() {
  const chartContainerRef = useRef();

  useEffect(() => {
    const chart = createChart(chartContainerRef.current, { width: 400, height: 300 });
    const lineSeries = chart.addLineSeries();
    const data = [
      { time: '2019-04-11', value: 80.01 },
      { time: '2019-04-12', value: 96.63 },
      { time: '2019-04-13', value: 76.64 },
      { time: '2019-04-14', value: 81.89 },
      { time: '2019-04-15', value: 74.43 },
      { time: '2019-04-16', value: 80.01 },
      { time: '2019-04-17', value: 96.63 },
      { time: '2019-04-18', value: 76.64 },
      { time: '2019-04-19', value: 81.89 },
      { time: '2019-04-20', value: 74.43 },
    ];
    lineSeries.setData(data);

    // Function to adjust chart size and fit content
    const resizeChart = () => {
      if (chartContainerRef.current) {
        const { width, height } = chartContainerRef.current.getBoundingClientRect();
        chart.applyOptions({ width, height });
        chart.timeScale().fitContent();
      }
    };

    // Initial resize to fit container
    resizeChart();

    // Add resize observer for responsive adjustments
    const resizeObserver = new ResizeObserver(entries => {
      resizeChart();
    });
    resizeObserver.observe(chartContainerRef.current);

    return () => {
      chart.remove();
      resizeObserver.disconnect();
    };
  }, []);

  return <div ref={chartContainerRef} style={{ width: '100%', height: '100%' }} />;
}

export default ChartComponent;
