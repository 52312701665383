import React, { useMemo } from 'react';
import { useTable } from 'react-table';

function TableComponent() {
  // Setup data and columns for react-table
  const data = useMemo(
    () => [
      { asset: 'NVDA', units: 15, value: 12000 },
      { asset: 'AAPL', units: 2, value: 300 },
      { asset: 'NTDOY', units: 200, value: 2400 }
    ],
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Asset',
        accessor: 'asset'
      },
      {
        Header: 'Units',
        accessor: 'units'
      },
      {
        Header: 'Value',
        accessor: 'value'
      }
    ],
    []
  );

  // Use the useTable hook to create your table configuration
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data });

  // Render UI for your table
  return (
    <div>
      <table {...getTableProps()} style={{ width: '100%', border: '1px solid black' }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} style={{ borderBottom: '2px solid black', background: 'aliceblue', fontWeight: 'bold' }}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()} style={{ padding: '10px', border: 'solid 1px gray' }}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default TableComponent;
