import React, { useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

const domain = window.location.host;

function ImportComponent() {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [selectedFile, setSelectedFile] = useState(null);

  console.log("Is: " + isAuthenticated);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert('No file selected.');
      return;
    }
    console.log('Uploading file:', selectedFile.name);

    const formData = new FormData();
    formData.append('file', selectedFile);

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: `https://${domain}/api/`,
        scope: "read:current_user"
      },
    });

    fetch('/api/import', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
      body: formData,
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then(result => {
        console.log(result);
        alert('File uploaded successfully');
      })
      .catch(error => {
        console.error('Error uploading file:', error);
        alert('Error uploading file');
      });
  };

  return (
    <div className="container text-center mt-5">
      <div className="card p-4 mt-4">
        <div className="card-body">
          <h5 className="card-title">Upload a File</h5>
          <div className="form-group">
            <input type="file" className="form-control mb-3" onChange={handleFileChange} />
            <button className="btn btn-primary" type="button" onClick={handleUpload} disabled={!isAuthenticated}>Upload</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImportComponent;
